/* ViewAllStockPage.css */

.ViewAllStockPage {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.page-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.table-container {
  overflow-x: auto;
}

.stock-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.stock-table th,
.stock-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.stock-table th {
  background-color: #4caf50;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
}

.stock-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.stock-table tr:hover {
  background-color: #f1f1f1;
}

.no-data {
  text-align: center;
  color: #999;
}

.sign-out-button {
  display: block;
  margin: 0 auto;
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}

.sign-out-button:hover {
  background-color: #c9302c;
}
/* Go Back Button Styling */
.go-back-button {
  display: block;
  margin: 20px auto;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}

.go-back-button:hover {
  background-color: #45a049;
}
