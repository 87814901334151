/* Common styles for all screen sizes */
.App {
  text-align: center;
}

/* Logo styles */
.App-logo {
  height: 10vmin;
  padding-top: 50px;
  pointer-events: none;
  /* Add any other styles you need */
}

.infotext{
  font-size: '12px';
  text-align: center;
  padding: '10px' 
}

/* Full-screen container */
.full-screen-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff; /* Optional background color */
}

/* Typography and Buttons */
.bigNumber {
  font-size: 36px;
  margin: 0;
}

.emailtext {
  color: blue;
  margin-right: 5px;
}

.buttonspaceA,
.buttonspaceB {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: 100px;
  height: 40px;
  font-size: 14px;
  margin: 5px;
  border-radius: 5%;
}

.inputspaceA {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: 80px;
  height: 40px;
  font-size: 16px;
  margin: 5px;
  text-align: center;
  color: black;
  border: 1px solid #00c70a;
  border-radius: 5%;
}

/* Card and Text Styles */
.cardTitle {
  font-size: 14px;
  margin: 10px 0;
}

.smalltext {
  font-size: 8px;
  margin: 0;
}

/* Header Styles */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 0%;
}

/* Number Item Styles */
.NumberItemContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
}

.NumberItem {
  margin: 10px;
  text-align: center;
  border: 3px solid #ccc;
  padding: 5px;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Links */
.App-link {
  color: #61dafb;
}

/* Media Queries for Responsive Design */

/* Mobile Phones (up to 767px) */
@media (max-width: 300px) {

  .App-logo {
    height: 25vmin;
    padding-top: 50px;
    pointer-events: none;
    /* Add any other styles you need */
  }
  
  .infotext{
    font-size: 10px;
    text-align: center;
    padding: 10px 
  }
  /* Adjust styles for mobile phones here */
  .bigNumber {
    font-size: 18px;
  }
  .buttonspaceA,
  .buttonspaceB {
    width: 70px;
    height: 30px;
    font-size: 10px;
  }
  .inputspaceA {
    width: 60px;
    height: 30px;
    font-size: 12px;
  }
  .cardTitle {
    font-size: 12px;
  }
  .smalltext {
    font-size: 8px;
  }

  .NumberItem {
    margin: 10px;
    text-align: center;
    border: 3px solid #ccc;
    padding: 5px;
    width: 40px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* Tablets (768px to 1023px) */
@media (min-width: 300px) and (max-width: 1023px) {

  .App-logo {
    height: 100px;
    padding-top: 50px;
    pointer-events: none;
    /* Add any other styles you need */
  }
  
  .infotext{
    font-size: '12px';
    text-align: center;
    padding: '10px' 
  }
  /* Adjust styles for tablets here */
  .bigNumber {
    font-size: 36px;
  }
  .NumberItem {
    margin: 10px;
    text-align: center;
    border: 3px solid #ccc;
    padding: 5px;
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttonspaceA,
  .buttonspaceB {

    width: 100px;
    height: 40px;
    font-size: 14px;
  }
  .inputspaceA {
    width: 80px;
    height: 40px;
    font-size: 16px;
  }
  .cardTitle {
    font-size: 14px;
  }
}

/* PC Screens (1024px and above) */
@media (min-width: 1024px) {
  /* Adjust styles for PC screens here */
  /* You can leave these styles as they are or make further adjustments */
}
